$white:			#ffffff;
$black:			#000000;

$colors:(
	"white":		$white,
	"black":		$black,
);


// Btn
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-line-height: 1.3;
$btn-line-height-sm: 1.3;
$btn-line-height-lg: 1.3;

$border-radius: 0;


$font-size-base:  1.08rem;
$line-height-base:  2.2;

$container-max-widths: (
	sm: 720px,
	md: 721px,
	lg: 1000px,
	xl: 1001px
);

$spacers: (
	0: 0px,
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
	6: 6px,
	7: 7px,
	8: 8px,
	9: 9px,
	10: 10px,
	11: 11px,
	12: 12px,
	13: 13px,
	14: 14px,
	15: 15px,
	16: 16px,
	17: 17px,
	18: 18px,
	19: 19px,
	20: 20px,
	25: 25px,
	30: 30px,
	35: 35px,
	40: 40px,
	45: 45px,
	50: 50px,
	55: 55px,
	60: 60px,
	65: 65px,
	70: 70px,
	75: 75px,
	80: 80px,
	85: 85px,
	90: 90px,
	95: 95px,
	100: 100px,
	110: 110px,
	120: 120px,
	130: 130px,
	140: 140px,
	150: 150px,
	160: 160px,
	170: 170px,
	180: 180px,
	190: 190px,
	200: 200px
);

$font-size: (
	10: 10px,
	12: 12px,
	14: 14px,
	16: 16px,
	18: 18px,
	20: 20px,
	22: 22px,
	24: 24px,
	26: 26px,
	28: 28px,
	30: 30px,
	32: 32px,
	34: 34px,
	36: 36px,
	38: 38px,
	40: 40px,
	42: 42px,
	44: 44px,
	46: 46px,
	48: 48px,
	50: 50px,
	52: 52px,
	54: 54px,
	56: 56px,
	58: 58px,
	60: 60px,
	62: 62px,
	64: 64px,
	66: 66px,
	68: 68px,
	70: 70px,
	72: 72px,
	74: 74px,
	76: 76px,
	78: 78px,
	80: 80px,
	100: 100px,
	110: 110px,
	120: 120px,
	130: 130px,
	140: 140px,
	150: 150px,
	160: 160px,
	170: 170px,
	180: 180px,
	190: 190px,
	200: 200px
);